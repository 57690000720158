/*
 * @Description: 
 * @Author: weig
 * @Date: 2021-08-16 17:12:31
 * @LastEditors: weig
 * @LastEditTime: 2021-12-17 11:31:45
 */
import request from '../../utils/request'
import EnumConfig from '../../enum/EnumConfig'

var CURR_API_VERSION = EnumConfig.EnumConfig.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var API_VERSION = CURR_API_VERSION == 'V0' ? '' : `/S/${CURR_API_VERSION}`;

//红包配置查询单条
export const RedPackGet=(params)=>{
    return request.apiGet(`/Api${API_VERSION}/Sales/SalesRedPack/Get`,params)
}
//红包配置分页查询
export const GetPage=(params)=>{
    return request.apiGet(`/api${API_VERSION}/Sales/SalesRedPack/GetPage`,params)
}

//红包配置添加
export const Add=(params)=>{
    return request.apiPost(`/api${API_VERSION}/Sales/SalesRedPack/Add`,params)
}
//红包配置修改
export const Update=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Sales/SalesRedPack/Update`,params)
}
//红包配置删除
export const SoftDelete=(params)=>{
    return request.apiDelete(`/api${API_VERSION}/Sales/SalesRedPack/SoftDelete?RedPackId=`+params)
}

//红包配置批量删除
export const BatchSoftDelete=(params)=>{
    return request.apiPut(`/api${API_VERSION}/Sales/SalesRedPack/BatchSoftDelete`,params)
}